var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Layout',[_c('PageHeader',{attrs:{"title":_vm.title,"items":_vm.items}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('b-tabs',{attrs:{"nav-class":"nav-tabs-custom"}},[_c('b-tab',{attrs:{"title-link-class":"p-3"}},[_c('div',{staticClass:"row mt-4"},[_c('div',{staticClass:"col-sm-12 col-md-6"},[_c('div',{staticClass:"dataTables_length",attrs:{"id":"tickets-table_length"}},[_c('label',{staticClass:"d-inline-flex align-items-center"},[_vm._v(" Afficher  "),_c('b-form-select',{attrs:{"size":"sm","options":_vm.pageOptions},model:{value:(_vm.perPage),callback:function ($$v) {_vm.perPage=$$v},expression:"perPage"}}),_vm._v(" entrées ")],1)])]),_c('div',{staticClass:"col-sm-12 col-md-6"},[_c('div',{staticClass:"dataTables_filter text-md-right",attrs:{"id":"tickets-table_filter"}},[_c('label',{staticClass:"d-inline-flex align-items-center"},[_vm._v(" Rechercher: "),_c('b-form-input',{staticClass:"form-control form-control-sm ml-2",attrs:{"type":"search"},model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}})],1)])])]),_c('div',{staticClass:"table-responsive"},[_c('b-table',{staticClass:"table-centered",attrs:{"items":_vm.ordersData,"fields":_vm.fields,"responsive":"sm","per-page":_vm.perPage,"current-page":_vm.currentPage,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"filter":_vm.filter,"filter-included-fields":_vm.filterOn},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event},"filtered":_vm.onFiltered},scopedSlots:_vm._u([{key:"cell(paymentstatus)",fn:function(row){return [_c('div',{staticClass:"badge font-size-12",class:{'badge-soft-danger': ("" + (row.value)) === 'Chargeback',
                          'badge-soft-success': ("" + (row.value)) === 'Paid',
                          'badge-soft-warning': ("" + (row.value)) === 'Unpaid'}},[_vm._v(_vm._s(row.value))])]}},{key:"cell(about)",fn:function(row){return [_c('div',{staticClass:"badge font-size-12",class:{
                        
                        'badge-soft-danger': ("" + (row.value)) === 'ERROR' ? 'badge-soft-danger': ("" + (row.value)) === 'FAILED',
                        'badge-soft-success': ("" + (row.value)) === 'PAID',
                        'badge-soft-warning': ("" + (row.value)) === 'INITIAL',
                      }},[_vm._v(" "+_vm._s(row.value)+" ")])]}},{key:"cell(invoice)",fn:function(){return [_c('button',{staticClass:"btn btn-light btn-rounded"},[_vm._v(" Invoice "),_c('i',{staticClass:"mdi mdi-download ml-2"})])]},proxy:true},{key:"cell(action)",fn:function(){return [_c('a',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"text-danger",attrs:{"href":"javascript:void(0);","title":"Delete"}},[_c('i',{staticClass:"mdi mdi-trash-can font-size-18"})])]},proxy:true}])})],1),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"dataTables_paginate paging_simple_numbers float-right"},[_c('ul',{staticClass:"pagination pagination-rounded mb-0"},[_c('b-pagination',{attrs:{"total-rows":_vm.rows,"per-page":_vm.perPage},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)])])])])],1)],1)])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }